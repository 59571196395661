<!--
 * @Author: ChenYaJin
 * @Date: 2023-11-17 10:54:25
 * @LastEditors: wjc
 * @LastEditTime: 2023-12-08 10:30:02
 * @Description: 活动评分列表
-->
<template>
  <div>
    <aside v-for="item in scoreList" :key="item.activityId" class="score-item">
      <label class="fw-bold flex-1">{{ item.activityName }}</label>
      <div>
        <span>
          活动得分：
          <span class="inline-block w-62px">
            <el-tag
              v-if="item.status === 'finished'"
              type="success"
              size="large"
              class="!font-s-1.2rem"
            >
              {{ item.averageScore?.toFixed(2) || 0 }}
            </el-tag>
            <span v-else>评分中</span>
          </span>
        </span>
        <span class="inline-block w-70px">
          <el-button
            v-if="item.status === 'finished'"
            type="primary"
            link
            class="underline ml-10px"
            @click="onOpenScoreDetail(item)"
          >
            活动详情
          </el-button>
        </span>
      </div>
    </aside>
  </div>
  <ActivityScoreDetail :modal-data="modalSetting" @close="onClose" />
</template>

<script setup lang="ts">
  import type { ModalData } from '~/models/common'
  import type { IActivityScore } from '~/models/activity'
  import ActivityScoreDetail from './activity-score-detail.vue'

  defineOptions({
    name: 'ActivityScoreList',
  })

  interface Props {
    scoreList: IActivityScore[]
  }
  withDefaults(defineProps<Props>(), {
    scoreList: () => {
      return []
    },
  })

  const modalSetting = ref<ModalData<IActivityScore>>({
    isShow: false,
    title: '活动标题',
    loading: false,
    row: {},
  })
  const onOpenScoreDetail = (activity: IActivityScore) => {
    modalSetting.value.isShow = true
    modalSetting.value.title = `${activity.activityName} - 活动详情`
    modalSetting.value.row = activity
  }
  const onClose = () => {
    modalSetting.value.isShow = false
  }
</script>

<style scoped lang="scss">
  .score-item {
    @apply flex-y-center flex-justify-between w-full h-50px m-v-10px p-h-15px;
    background-color: #f7f9fb;
    border: 1px solid #e5e5e5;
  }
</style>
